.home {
  background-color: #c0c0c0;
  padding: 20px;
}

.game {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.container {
  display: flex;
  justify-content: center;
}

.top-bar {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  background-color: #c0c0c0;
  padding: 4px;
}

.top-bar >div {
  display: flex;
  align-items: center;
}

.top-bar >div:last-child {
  display: flex;
  justify-content: flex-end;
}

.top-bar .dseg {
  font-family: "DSEG7 Classic";
  color: red;
  background: black;
  font-size: 2rem;
  padding: 1px;
  margin: 0 4px;
  border-top: 3px solid #808080;
  border-left: 3px solid #808080;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}

div.smile {
  width: 64px;
  height: 64px;
  background-image: url(/smile-normal.png);
  background-size: cover;
  background-color: #c0c0c0;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #808080;
  border-right: 3px solid #808080;
  padding: 6px;
}

div.smile:active {
  background-image: url(/smile-pressed.png);
  border-top: 3px solid #808080;
  border-left: 3px solid #808080;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}

div.smile.lost {
  background-image: url(/smile-lost.png);
}

div.smile.wondering {
  background-image: url(/smile-wondering.png);
}

div.smile.won {
  background-image: url(/smile-won.png);
}
section.board-container {
  display: flex;
  justify-content: center;
  background-color: #c0c0c0;
}

section.board {
  display: grid;
}

section.board div {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

section.board div.unknown {
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #808080;
  border-right: 3px solid #808080;
}

section.board div.unknown:active {
  border: 0.5px solid #808080;
}

section.board div.clear {
  border: 0.5px solid #808080;
}

section.board div.mine {
  background-image: url(/mine.png);
  background-size: contain;
}

section.board div.unknown-mine {
  background-image: url(/mine.png);
  background-size: contain;
}

section.board div.lost {
  background-image: url(/mine.png);
  background-size: contain;
  background-color: red;
}

section.board div.flag {
  background-image: url(/flag.png);
  background-size: contain;
}

.toolbar {
  background-color: #c0c0c0;
  padding: 6px;
}

.checkbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
