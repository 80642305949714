nav {
  display: flex;
  padding: 16px 8px;
  justify-content: space-evenly;
  background-color: #c0c0c0;
}

nav .links {
  display: flex;
  flex-direction: column;
}

nav .links a {
  display: inline-block;
  color: black;
  text-decoration: none;
  padding: 8px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #808080;
  border-right: 3px solid #808080;
}

nav .links a.active {
  border-top: 3px solid #808080;
  border-left: 3px solid #808080;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}

nav .profile {
  display: flex;
}

nav .profile img {
  max-height: 100px;
  border-radius: 50%;
}

nav .profile>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
}

nav .profile>div h2 {
  margin: 2px 0;
}

nav .profile>div p {
  margin: 2px 0;
}
