section.games {
  background-color: #c0c0c0;
  padding: 10px 40px;
}

section.games.signed-out {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

section.games ul {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 50vh;
  overflow: auto;
}

section.games ul li {
  padding: 8px;
}
