section.new-game {
  background-color: #c0c0c0;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

section.new-game .mode {
  display: flex;
}

section.new-game .mode div {
  cursor: default;
  user-select: none;
  padding: 8px;
  margin: 8px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #808080;
  border-right: 3px solid #808080;
}

section.new-game .mode div.active {
  border-top: 3px solid #808080;
  border-left: 3px solid #808080;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}

section.new-game input[type="number"] {
  max-width: 75px;
  margin: 6px;
}

section.new-game button.start {
  margin: 12px;
}
